@tailwind base;
@tailwind components;
@tailwind utilities;
.scrollbar-gutter {
  scrollbar-gutter: stable;
}
.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.scrollbar-styled::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.scrollbar-styled::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollbar-styled::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.scrollbar-styled::-webkit-scrollbar-thumb:hover {
  background: #555;
}
